import React, { useState } from 'react';


function returnOptions(props) {
  if (props.multiple === true) {
    return props.options
  } else {
    return [props.options]
  }
}

function returnElements(options, props) {
  if (options[0] === null) {
    return options.map((option)=> <input
      type="hidden"
      key={"autocomplete_option_null"}
      value={''}
      name={props.name} />
    );
  } else {
    return options.map((option)=> <input
      type="hidden"
      key={"autocomplete_option_" + option[1]}
      value={option[1]}
      name={props.name} />
    );
  }
}

function LedgeromaticAutocompleteHiddenInputs(props) {
  const options = returnOptions(props)
  const elements = returnElements(options, props);

  return (
    <React.Fragment>
      {elements}
    </React.Fragment>
  )
}

export default LedgeromaticAutocompleteHiddenInputs
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LedgeromaticAutocompleteHiddenInputs from './LedgeromaticAutocompleteHiddenInputs.jsx';

function LedgeromaticAutocomplete(props) {
  const [selectedOption, setSelectedOption] = useState(props.selected || null);

  return  (
    <React.Fragment>
      <div id={props.id + '_autocomplete_selected_items'}>
        <LedgeromaticAutocompleteHiddenInputs
          options={selectedOption || []}
          name={props.name}
          multiple={props.multiple}
        />
      </div>

      <Autocomplete
        id={props.id + '_autocomplete'}
        options={props.options}
        renderInput={params => {
          return (
            <TextField {...params}
              variant="outlined"
              placeholder={props.label}
              style={{background:"white"}}
              className="shadow-none"
            />
          );
        }}
        getOptionLabel={option => option[0]}
        getOptionSelected={(option, value) => option[1] === value[1]}
        multiple={props.multiple}
        autoSelect={true}
        autoHighlight={false}
        disableClearable={props.disableClearable}
        value={selectedOption}
        onChange={(_event, selectedOption) => {
          setSelectedOption(selectedOption);
          LedgeromaticAutocompleteHiddenInputs({
            options: selectedOption,
            name: props.name,
            multiple: props.multiple
          });
        }}
      />
    </React.Fragment>
  )
}

export default LedgeromaticAutocomplete
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

function goToResource(resource_id, goToPath) {
  window.location.href = goToPath + '/' + resource_id;
}

function AutocompleteAndGo(props) {
  const [selectedOption, setSelectedOption] = useState(props.selected || null);

  return  (
    <React.Fragment>
      <Autocomplete
        id={props.id + '_autocomplete_and_go'}
        options={props.options}
        renderInput={params => {
          return (
            <TextField
              {...params}
              variant="outlined"
              placeholder={props.label}
              style={{background:"white"}}
            />
          );
        }}
        getOptionLabel={option => option[0]}
        getOptionSelected={(option, value) => option[1] === value[1]}
        multiple={props.multiple}
        autoSelect={true}
        autoHighlight={false}
        disableClearable={props.disableClearable}
        value={selectedOption}
        onChange={(_event, selectedOption) => {
          setSelectedOption(selectedOption);
          goToResource(selectedOption[1], props.goToPath);
        }}
      />
    </React.Fragment>
  )
}

export default AutocompleteAndGo